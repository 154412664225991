::-webkit-scrollbar {
    width: 5px;
    background-color: transparent;
    height: 5px;
}

::-webkit-scrollbar-track-piece {
    background-color: transparent;
}

::-webkit-scrollbar-thumb {
    background-color: rgba(188, 188, 188, 1);
    border-radius: 20px;
}
::-webkit-scrollbar-button {
    display: none;
}